<template>
    <div class="grid-container">
      <h1 class="grid-title">TaRaLohk OÜ - Kontakt</h1>
      <div class="grid-item left-column">
        <h2>Perenaine - Raini</h2>
      </div>
      <div class="grid-item right-column">
        <h2>Peremees - Tarmo</h2>
      </div>
    </div>
  </template>
  
  <style scoped>

  body{
    background-color: #FFD7B5;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    grid-template-rows: auto 1fr; /* Two rows, auto height for the title, 1fr for the content */
    gap: 10px; /* Optional gap between grid items */
  }
  
  .grid-title {
    grid-column: 1 / -1; /* The title spans from the first to the last column */
  }
  
  .grid-item {
    padding: 20px; /* Padding inside grid items */
   /* border: 1px solid #ccc;  Border for grid items */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .left-column {
    /* Additional styling for the left column if needed */
  }
  
  .right-column {
    /* Additional styling for the right column if needed */
  }
  </style>
  