<template>
  <div class="home">
    <div class="about">
      <h1 class="title-playfair">TaRaLohk OÜ - Perenaise Pagariäri</h1>
      <h2 class="contact-playfair">Raini - Tel.53363646 - info@taralohk.ee</h2>
      <a href="https://www.facebook.com/groups/1464662113710052" class="facebook-link">Raini Heegeldamised Facebook</a>
    </div>
    <vue-picture-swipe :items="items"></vue-picture-swipe>
    
  </div>
 
</template>
  
<script>
import VuePictureSwipe from 'vue-picture-swipe';

export default {
  components: {
    VuePictureSwipe
  },
  data() {
    return {
      items: [
        {
          src: require('../assets/gallery/image1.jpg'),
          thumbnail: require('../assets/gallery/image1.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image6.jpg'),
          thumbnail: require('../assets/gallery/image6.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image2.jpg'),
          thumbnail: require('../assets/gallery/image2.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image3.jpg'),
          thumbnail: require('../assets/gallery/image3.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image4.jpg'),
          thumbnail: require('../assets/gallery/image4.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image5.jpg'),
          thumbnail: require('../assets/gallery/image5.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image14.jpg'),
          thumbnail: require('../assets/gallery/image14.jpg'),
          w: 768,
          h: 1024
        },
        {
          src: require('../assets/gallery/image14.png'),
          thumbnail: require('../assets/gallery/image14.png'),
          w: 768,
          h: 1024
        },
        {
          src: require('../assets/gallery/image9.jpg'),
          thumbnail: require('../assets/gallery/image9.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image7.jpg'),
          thumbnail: require('../assets/gallery/image7.jpg'),
          w: 1024,
          h: 462
        },
        {
          src: require('../assets/gallery/image11.jpg'),
          thumbnail: require('../assets/gallery/image11.jpg'),
          w: 1024,
          h: 768
        },
        {
          src: require('../assets/gallery/image12.jpg'),
          thumbnail: require('../assets/gallery/image12.jpg'),
          w: 1024,
          h: 768
        },
        // Add more images as needed...
      ]
    };
  }
};
</script>
  
<style scoped>
.home{
  width: 100%;
    
    background-color: #f0d9b7;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.about{
  padding: 0px 0px 60px;
}
.title-playfair{
  text-align: center;
  color: black;
  font-family: Playfair Display;
  font-size: 3dvw;
}
.contact-playfair{
  text-align: center;
    color: black;
    font-family: Playfair Display;
    font-size: 2.2dvw;
    padding: 10px 0px;
}

figure a img {
    max-width: 45%;
}

.facebook-link{
 color: black;
 font-family: Playfair Display;
 font-size: 2.2dvw;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .title-playfair {
    font-size: 4dvw;
  }
  .contact-playfair {
    font-size: 4dvw;
  }
}

@media only screen and (max-width: 768px) {
  .title-playfair {
    font-size: 5dvw;
  }
  .contact-playfair {
    font-size: 5dvw;
  }
  .facebook-link{
    font-size: 5dvw;
  }
}




@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  src: url('../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf') format('truetype-variations');
  font-display: swap;
}
</style>