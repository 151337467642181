<template>
  <div class="home">
    <div class="middle-text">
      <img src="../assets/logo_transparent.png" style="width:100%" alt="TaRaLohk OÜ logo">
    </div>
    <div class="split left" @click="goToCakes">
      <div ref="leftBackground" class="background left-bg"></div>
      <div id="mySidenav" class="sidenav" @mouseover="() => toggleBlurEffect('left', 'add')" @mouseleave="() => toggleBlurEffect('left', 'remove')">
          <a id="about">Perenaise Pagariäri</a>
      </div>
      <div class="child-container left"></div>
      <div class="down-arrow"></div>
    </div>
    <div class="split right" @click="goToMechanic">
      <div ref="rightBackground" class="background right-bg"></div>
      <div id="mySidenavRight" class="sidenavRight" @mouseover="() => toggleBlurEffect('right', 'add')" @mouseleave="() => toggleBlurEffect('right', 'remove')">
          <a id="blog">Peremehe Töövaldkond</a>
      </div>
      <div class="child-container right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    goToCakes() {
      this.$router.push({ name: 'perenaine' });
    },
    goToMechanic() {
      this.$router.push({ name: 'peremees' });
    },
    toggleBlurEffect(splitSide, action) {
      const background = this.$refs[`${splitSide}Background`];
      if (background) {
        if (action === 'add') {
          background.classList.add('blur-effect');
        } else if (action === 'remove') {
          background.classList.remove('blur-effect');
        }
      }
    }
  }
}
</script>

<style scoped>

.home {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: #FFD7B5;
}

.split {
  width: 50%;
  position: fixed;
  height: 100%;
  cursor: pointer;
}


.right { right: 0; }

.background {
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s linear;
  width: 100%;
  position: absolute;
  height: 100%;
  cursor: pointer;
}

.left-bg { 
  background-image: url('../assets/taralohk_vasak.png');
  background-position: right; 
}
.right-bg { 
  background-image: url('../assets/taralohk_parem.png');
  background-position: left;
}

.background:hover { filter: blur(3px); }

.blur-effect {
  filter: blur(3px);
}
.background:hover + #mySidenav a {
    left: 0;
}

.background:hover + #mySidenavRight a {
    right: 0;
}

.child-container {
  width: 40%;
  height: 100%;
  position: absolute;
}

.child-container.left {
  right: 0;
  background: rgba(255, 255, 255, 0); /* Adjust background as needed */
}

.child-container.right {
  left: 0;
  background: rgba(255, 255, 255, 0); /* Adjust background as needed */
}

.middle-text { 
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 3;
  top: 41vh;
  width: 22%;
}

.company-name-style {
 
  font-size: 2.9vw;
  color: rgb(0, 0, 0);
  font-family: Playfair Display;
  font-style: normal;
  text-wrap: wrap;
  overflow-wrap: break-word;
  white-space-collapse: break-spaces;
  -webkit-font-smoothing: antialiased;
}

#mySidenav a {
  position: absolute;
    font-family: Playfair Display;
    font-weight: 500 medium;
    left: -80px;
    transition: 0.3s;
    padding: 15px 15px 15px 20px;
    width: auto;
    text-decoration: none;
    font-size: 4vw;
    color: beige;
    border-radius: 0 5px 5px 0;
    /* line-height: 26px; */
    /* margin-bottom: 10px; */
    box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
}

#mySidenav a:hover { left: 0; }

#mySidenav a::before {
  content: '←'; /* Leftward arrow */
  display: inline-block;
  margin-right: 40px; /* Space between the arrow and the text */
}

#mySidenavRight a::after {
  content: '→'; /* You can change this to any arrow symbol you prefer */
  display: inline-block;
  margin-left: 40px; /* Adjust the spacing between the arrow and the text */
}

#mySidenavRight a {
  position: absolute;
    font-family: Playfair Display;
    font-weight: 500 medium;
    right: -80px;
    transition: 0.3s;
    padding: 15px 20px 15px 15px;
    width: auto;
    text-decoration: none;
    font-size: 3.5vw;
    color: beige;
    border-radius: 5px 0px 0px 5px;
    /* line-height: 26px; */
    /* margin-bottom: 10px; */
    box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
}

#mySidenavRight a:hover { right: 0; }


#about { top: 15%; background-color: #FF9248; }
#blog { bottom: 15%; background-color: #006B99; }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .split {
    width: 100%;
    position: relative;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
  }
  .middle-text{
    display:none;
  }

  .down-arrow::after {
    content: '↓';
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-size: 116px;
    color: white;
    text-shadow: 0px 0px 12px BLACK;
    animation: bounce 2s infinite;
  }
  #about{
    
    top: 45%;
    background-color: #FF9248;
  }
  #blog{
    bottom: 45%;
    
    background-color: #006B99;
  }


  .right-bg {
    transform: rotate(180deg);
  }
  .left-bg {
    transform: rotate(180deg);
  }
  #mySidenav a{
    font-size: 6vw;
  }
  #mySidenavRight a{
    font-size: 6vw;
  }
}

@media only screen and (max-width: 768px) {
  #mySidenav a{
    font-size: 8vw;
  }
  #mySidenavRight a{
    font-size: 7vw;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}


@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  src: url('../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf') format('truetype-variations');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  src: url('../assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype-variations');
  font-display: swap;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

</style>
