import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RainiView from '../views/RainiView.vue'
import TarmoView from '../views/TarmoView.vue'
import KontaktView from '../views/KontaktView.vue'

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/perenaine', name: 'perenaine', component: RainiView },
  { path: '/peremees', name: 'peremees', component: TarmoView },
  { path: '/kontakt', name: 'kontakt', component: KontaktView }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
