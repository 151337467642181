<template>

  <router-view/>
</template>

<style>
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.gallery-thumbnail {
  line-height: 30px;
}
nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.my-gallery {
    max-width: 70%;
    margin: 0 auto;
    margin-bottom: 70px;
}

figure a img {
    max-width: 45%;
}

.facebook-link{
 color: white;
 font-family: Oswald;
}

</style>
