<template>
  <div class="container">
    <h1 class="title">TaRaLohk OÜ - Peremehe Töövaldkond</h1>
    <h2 class="heading-Oswald">Tarmo - Tel.53439104 - info@taralohk.ee</h2>
    <a href="https://www.facebook.com/groups/236110317488687" class="facebook-link">TaRaLohk OÜ Facebook</a>
    <br>
    <h2 class="heading-Oswald" style="padding-top:60px; padding-bottom:20px">Teenuse broneering</h2>
    <div id="trBooking-container"></div>
    <h2 class="heading-Oswald" style="padding-top:60px;">Hinnakiri</h2>
    <div v-for="(category, index) in items" :key="`category-${index}`" class="category">
      <h2>{{ category.category }}</h2>
      <table>
        <thead>
          <tr>
            <th>~</th>
            <th>Tööaeg</th>
            <th>Hinnakiri millel lisandub käibemaks</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in category.services" :key="`service-${index}`">
            <td><b>{{ service.service }}</b> <br> {{ service.description || 'N/A' }}</td>
            <td>{{ service.duration || 'N/A' }}</td>
            <td>{{ service.price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  
  </div>
</template>

<script>
import $ from 'jquery';
window.$ = window.jQuery = $; // Make jQuery globally available

export default {
  data() {
    return {
      items: []
    };
  },
  mounted() {
    // Fetching JSON data
    fetch('/hinnakiri.json')
      .then(response => response.json())
      .then(data => {
        this.items = data;
      })
      .catch(error => console.error('Error loading the hinnakiri.json:', error));

    // Dynamically load the external script and initialize the jQuery plugin
    this.loadScript('https://trumpauto.ee/api/client/trumpautoBooking.js', () => {
      $(document).ready(() => {
        $("#trBooking-container").trumpBooking({apiKey: "56d4d4a953be48a68507d77f"});
      });
    });
  },
  methods: {
    loadScript(src, callback) {
      let script = document.createElement('script');
      script.onload = () => callback();
      script.src = src;
      document.head.appendChild(script); // Append to head to ensure global scope
    }
  }
}
</script>

<style>
.container {
  background: linear-gradient(145deg, #30484c, #43555c, #3c4e52);
  color: #ffffff;
  padding: 20px;
  min-height: 100vh;
}

.title{
  text-align: center;
  color: #ffffff;
  font-family: Oswald;
  font-size: 3dvw;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background: #fff;
  color: #333;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.category h2 {
  color: #ffffff;
}

.category {
  text-align: left;
  width: 80%;
  margin: 20px auto;
  padding: 15px;
}

.contact-Oswald{
  text-align: center;
  color: #ffffff;
  font-family: Oswald;
  font-size: 2.2dvw;
  padding: 10px 0px 60px;
}

.heading-Oswald{
  text-align: center;
  color: #ffffff;
  font-family: Oswald;
  font-size: 2.2dvw;
}

.facebook-link{
 color: white;
 font-family: Oswald;
 font-size: 2.2dvw;
 padding: 10px 0px 60px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .title {
    font-size: 4dvw;
  }
  .contact-Oswald {
    font-size: 4dvw;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 5dvw;
  }
  .contact-Oswald {
    font-size: 6dvw;
  }
  .facebook-link{
    font-size: 5dvw;
  }
}

</style>
